.dashboard__wrapper{
    width: 100%;
    height: 100%;
    background-color: black;
    
}
.cards__wrapper{
    width: 70%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card__container{
    width: 30%;
    height: 70%;
    background-color: #3200c8de;
    margin-left: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.card__container:hover{
    transform: scale(1.1);
}
.box{
    background: #FBD3E9;  /* fallback for old browsers */
background: -webkit-linear-gradient(to top,#3503ad, #F7308c);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(45deg, #3503ad, #F7308c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.card__container p{
    color: #ffffff;
    font-size: 1.5em;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

@media (max-width: 769px){
    .cards__wrapper{
        width: 50%;
        height: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .card__container{
        margin-top: 10px;
        width: 25%;
        height: 70%;
    }
}