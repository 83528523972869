* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .container {
    width: 80%;
    margin: auto;
    padding: 2rem;
    /* background-color: #fff; */
  }
  

  .form-heading {
    font-size: 1.5rem;
    color: #00f5f5;
    margin-bottom: 1rem;
  }
  
  /* File input wrapper */
  .file-input-wrapper {
    display: flex;
    align-items: center;
    background-color: #333;
    border: 1px solid #444;
    border-radius: 5px;
    padding: 1rem;
    transition: border-color 0.3s, background-color 0.3s;
  }
  
  .file-input-label {
    color: white;
    margin-right: 1rem;
    cursor: pointer;
  }
  
  .file-input-wrapper.active {
    border-color: #777;
    background-color: #333;
  }
  
  .file-input-label.active {
    text-decoration: underline;
  }
  
  .file-input-label:hover {
    text-decoration: underline;
  }
  

  .form-container {
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #111;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: white;
  }
  

  .form-label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  /* Form input */
  .form-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #444;
    border-radius: 4px;
    background-color: #222;
    color: white;
    transition: border-color 0.3s, background-color 0.3s;
  }
  
  .form-input:focus {
    outline: none;
    border-color: #777;
    background-color: #333;
  }
  

  .save-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
  }
  
  .save-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  
  .text-red-500 {
    color: #ff3d3d;
    margin-top: 0.5rem;
  }
  

  @media (max-width: 768px){
    .mb-2{
      overflow: hidden;
       text-overflow: ellipsis;
    }
    .mb-2 strong{
      font-size: 10px;
      overflow: hidden;
    }
  }