* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.center-container{
  height: auto;
  width: 50%;
  /* background-color: #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
}


.credit-card {
  width:100%;
  height: auto;
  /* margin: auto; */
  background-color: #151515;
  border-radius: 20px;
  color: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.text-center {
  text-align: center;
}

.mt-4 {
  margin-top: 1rem;
}

.p-4 {
  padding: 2rem;
}

.input {
  padding: 1rem;
  background-color: #222;
  border: 1px solid #444;
  border-radius: 5px;
  color: white;
  width: 100%;
  transition: border-color 0.3s, background-color 0.3s;
}

.input:focus {
  outline: none;
  border-color: #777;
  background-color: #333;
}


.file-input-wrapper {
  display: flex;
  align-items: center;
  background-color: #333;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 1rem;
  transition: border-color 0.3s, background-color 0.3s;
}

.file-input-label {
  color: white;
  margin-right: 1rem;
  cursor: pointer;
}
.file-input-wrapper.active {
  border-color: #777;
  background-color: #333;
}


.file-input-label.active {
  text-decoration: underline;
}

.file-input-label:hover {
  text-decoration: underline;
}


.form-heading {
  font-size: 1.5rem;
  color: #00f5f5; 
  margin-bottom: 1rem;
}


.btn {
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn-primary:hover,
.btn-secondary:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}



.selected-files {
  margin-top: 1rem;
  font-size: 1rem;
}

.selected-files p {
  color: #00f5f5;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.selected-files ul {
  list-style-type: none;
  padding-left: 0;
}

.selected-files li {
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  color:black;
}

.selected-files li:hover {
  transform: translateX(5px);
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
}


@media (max-width: 768px){
  .center-container{
    width: 100%;
  }
}
