*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.university__wrapper{
    width: 100%;
    height: 100vh;
    background: linear-gradient(135deg, hsla(253, 16%, 7%, 1) 0, transparent 50%),
    radial-gradient(at 50% 0%, hsla(225, 39%, 30%, 1) 0, transparent 50%),
    radial-gradient(at 100% 0%, hsl(339, 49%, 30%) 0, transparent 50%);
  background-color: #0f0e13;
  overflow: auto;

}
/* .university__navbar__container{
    width: 100%;
    height: 100px;
    background-color: #f72727;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
} */
.university__description{
    width: 100%;
    height: 500px;
    /* background-color: #fff; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.university__sign__container{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* background-color: #fff; */
    /* flex-direction: row; */
}

.university__3d__model__container{
    width: 45%;
    height: 100%;
    /* background-color: #866464; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.university__3d__model__container img{
    width: 80%;
    height: 80%;
}


.university__features__container{
    width: 55%;
    height: 100%;
    /* background-color: #f05656; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.university__features__inner__container{
    width: 90%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: #2fea4b; */
}
.university__features__heading{
    width: 100%;
    height: 200px;
    /* background-color: #fff; */
    text-align: center;
    /* margin-bottom: 10px; */
}


.university__feature{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 10px;
    border: 2px solid white;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
}
.university__feature::after{
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 0;
    background-color: #fff;
    border-radius: 8px;
    transition: width .4s ease;
    cursor: pointer;
    display: block;
    color: black;

}
.university__feature:hover::after{
    width: 100%;
}

.university__feature:hover svg{
    color: black;
    z-index: 99;
}
.university__feature:hover span{
    color: black;
    z-index: 99;
}



/* .university__feature:hover{ */
    /* animation: expand 4.s linear;
}

@keyframes expand{
    0%{
        width: 0;
    }
    25%{
        width: 25%;
    }
    50%{
        width: 50%;
    }
    75%{
        width: 75%;
    }
    100%{
        width: 100%;
    }
}  */

svg,span{
    cursor: pointer;
    color: #fff;
}


.transaction__info__container{
    width: 580px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #131111;
    color: white;
    margin-left: 20px;
    border-radius: 10px;
}
h2{
    color: #00f5f5;
}





.sign__message__container{
    width: 25%;
    height: 150px;
    background:linear-gradient(45deg, #42275a , #734b6d);
    margin-bottom: 70px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;
}
.sign__message__container:hover{
    transform: scale(1.1);
}

.sign__message__container p{
    color: white;
    cursor: pointer;
    font-size: 18px;

}
.dashboard__container{
    width: 25%;
    height: 150px;
    background:linear-gradient(45deg,#de6262, #ffb88c);
    margin-bottom: 70px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;

}
.dashboard__container:hover{
    transform: scale(1.1);

}
.dashboard__container p{
    cursor: pointer;
    color: white;
    font-size: 20px;
}












/* Media Queries for responsive design */

@media (max-width: 600px) {
    .university__description {
        flex-direction: column; 
        height: auto;
        padding: 20px; 
    }

    .university__3d__model__container,
    .university__features__container {
        width: 100%; 
    }

    .university__3d__model__container img {
        width: 100%;       height: auto;
    }

    .university__feature {
        flex-direction: column; 
        text-align: center; 
        padding: 10px; 
        border: none; 
        margin-bottom: 20px; 
    }

    .university__feature::after {
        display: none;
    }

    .university__feature svg,
    .university__feature span {
        margin-top: 10px; /* Add space between icon and text */
    }
    .university__feature span{
        font-size: 12px;
    }

    .university__sign__container {
        height: auto; /* Let the height adjust to content */
        margin-top: 20px; /* Add spacing at the top */
    }
    p{
        font-size: 12px;
    }
}
@media (max-width: 375px){
    *{
        margin: 0 auto;
    }
    .university__wrapper{
        width: 100%;

    }
}