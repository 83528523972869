*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.navbar__container{
    width: 100%;
    height: 80px;
    /* background-color: #ec7b7b; */
}
.fixed__navbar{
    width: 100%;
    height: 80px;
    background-color: #ec7b7b;
    position: fixed;
    top: 0;
    left: 0;
}
.metamask__btn{
    height: 40px;
    width: 180px;
    background: linear-gradient(135deg, hsla(253, 16%, 7%, 1) 0, transparent 50%),
    radial-gradient(at 50% 0%, hsla(225, 39%, 30%, 1) 0, transparent 50%),
    radial-gradient(at 100% 0%, hsl(339, 49%, 30%) 0, transparent 50%);
  background-color: #0f0e13;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

.logout__container{
    width: 40%;
    height: 100%;
    /* background-color: #fff; */
    float: right;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.logout__btn{
    width: 140px;
    height: 40px;
    background: linear-gradient(135deg, hsla(253, 16%, 7%, 1) 0, transparent 50%),
    radial-gradient(at 50% 0%, hsla(225, 39%, 30%, 1) 0, transparent 50%),
    radial-gradient(at 100% 0%, hsl(339, 49%, 30%) 0, transparent 50%);
  background-color: #0f0e13;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    color: white;
    cursor: pointer;
}
