body {
  background: black;
}

.parent__container {
    max-height: 100vh; /* Set a fixed maximum height for the parent container */
    overflow-y: auto;
    background-color: #fff;
  }
  

.details__wrapper{
    width: 100%;
    height: 100%;
    background-color: #494d5f;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: auto;
}