
.card__wrapper{
    width: 45%;
    height: 280px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px
}
.details__container__row{
    width: 90%;
    height: 20%;
    /* background-color: #333232; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.details__col{
    width: 90%;
    height: 15%;
    /* background-color: #505050; */
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}
.details__col h3{
    color: black;
    
}
.details__col h5{
    color: black;
}

.delete__btn__container{
    width: 90%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.delete__btn{
    float: right;
    width: 20%;
    height: 80%;
    background-color: #fff;
    color: white;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #4b18d8;
    cursor: pointer;
    outline: none;
    border: none;
}



@media (max-width: 768px) {
    .card__wrapper {
      width: 90%;
      max-width: none; /* Remove max-width for smaller screens */
    }
  
    .delete__btn {
      width: 50%; /* Further adjust button width for smaller screens */
    }
    .details__col h3{
        font-size: 12px;
    }
    .details__col h5{
        font-size: 12px;    
    }
}