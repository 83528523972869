*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}
.logo {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 150px; /* Adjust the width as needed */
}
.logo2{
  position: absolute;
  top: 20px;
  left: 20px;
  width: 150px; 
}

.body {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Nunito", sans-serif;
  background-image: url(images/bb3.jpeg);
  background-size: cover;
  color: #505f75;
}

.input {
  border: none;
  outline: none;
  background: none;
}

.cont {
  overflow: hidden;
  position: relative;
  width: 970px; /* Adjust the width as needed */
  height: 800px;
  background: #0504049c;
  box-shadow: 0 19px 38px rgba(125, 126, 124, 0.3),
    0 15px 12px rgba(236, 183, 183, 0);
  display: flex;
  transition: transform 0.7s ease-in-out;
}

.form-content {
  flex: 1; /* Occupy remaining space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
}

.form.sign-up {
  transform: translateX(0);
}

.form.sign-in {
  transform: translateX(0);
}

h2 {
  width: 100%;
  color: #656565;
  font-size: 30px;
  text-align: center;
}

.label {
  display: block;
  width: 260px;
  margin: 10px auto 0;
  text-align: center;
}

.label,
.span {
  font-size: 14px;
  font-weight: 600;
  color: #505f75;
  text-transform: uppercase;
}

.input {
  display: block;
  width: 100%;
  margin-top: 5px;
  font-size: 16px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(109, 93, 93, 0.4);
  text-align: center;
  font-family: "Nunito", sans-serif;
  color: #fff;
}

.submit {
  display: block;
  margin: 0 auto;
  width: 260px;
  height: 36px;
  border-radius: 30px;
  color: #5e7190;
  font-size: 15px;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  margin-top: 40px;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
  background: linear-gradient(to right, #523c95, #391f68);
}

.submit:hover {
  background: linear-gradient(to right, #391f68, #523c9568);
}

.error {
  color: red;
  font-size: 14px;
  text-align: center;
}

.video {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.link {
  cursor: pointer;
}